import '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Lenis from 'lenis'
import './scripts/glightbox';
import './css/app.scss';

// Start Stimulus application
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context));
application.debug = process.env.NODE_ENV === 'development';

// Automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

// Smooth scrolling
const lenis = new Lenis();

(function lenisRaf(time) {
    lenis.raf(time);

    window.requestAnimationFrame(lenisRaf);

    return lenisRaf;
})();
