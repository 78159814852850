import { Controller } from '@hotwired/stimulus'
import ScrollMagic from 'scrollmagic'
import { foreachElement } from '../scripts/foreachElement';

export default class extends Controller {
    static targets = ['element']
    static values = {
        hook: { type: Number, default: 0.85 }
    }

    controller = new ScrollMagic.Controller();
    scenes = new WeakMap;

    connect() {
        foreachElement('#main .mod_article > .inside > *', (element) => {
            if (this.scenes.has(element)) {
                return;
            }

            element.classList.add('scroll-effect');

            const scene = new ScrollMagic.Scene({
                triggerElement: element,
                triggerHook: this.hookValue,
            })
                .setClassToggle(element, 'scroll-effect--start')
                .addTo(this.controller)
            ;

            this.scenes.set(element, scene);
        });
    }

    elementTargetConnected(element) {
        if (this.scenes.has(element)) {
            return;
        }

        element.classList.add('scroll-effect');

        const scene = new ScrollMagic.Scene({
            triggerElement: element,
            triggerHook: this.hookValue,
        })
            .setClassToggle(element, 'scroll-effect--start')
            .addTo(this.controller)
        ;

        this.scenes.set(element, scene);
    }

    elementTargetDisconnected(element) {
        this.scenes.get(element).destroy(true);
    }
}
