import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        factor: { type: Number, default: 0.65 }
    }

    updatePositon(element) {
        const bp = parseFloat(getComputedStyle(document.body).getPropertyValue('--bp-tablet'));

        if (window.innerWidth < bp) {
            element.style.removeProperty('transform');
        } else {
            const translate = (window.scrollY + window.innerHeight - document.documentElement.scrollHeight) * this.factorValue;
            element.style.transform = 'translateY('+translate+'px)';
        }

        window.requestAnimationFrame(() => { this.updatePositon(element) });
    }
    
    connect() {
        window.requestAnimationFrame(() => { this.updatePositon(this.element) });
    }
}
