import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['fragment']

    fragmentTargetConnected(fragment) {
        const index = parseInt(fragment.dataset.index, 10);
        const img = fragment.querySelector('img');

        const imgTx = Math.random() * 180 - 90;
        const imgTy = Math.random() * 140 - 70;
        const imgR = Math.random() * 80 - 40;

        img.style.transform = `translateX(${imgTx}px) translateY(${imgTy}px) rotateZ(${imgR}deg)`;

        setTimeout(() => {
            img.classList.add('transition');
        });

        const imgComplete = () => {
            setTimeout(() => {
                fragment.classList.add('show');
            }, 250);
        };

        if (img.complete) {
            imgComplete();
        } else {
            img.addEventListener('load', imgComplete);
        }

        (function updateAnimation() {
            const translateX =  window.scrollY * (-0.03 * index * (index % 2 === 0 ? 1 : -1));
            const translateY = window.scrollY * (0.13 * index);
            const rotate = window.scrollY * (0.0055 * index * (index === 2 ? 1 : -1));
            fragment.style.transform = 'translateX('+translateX+'px) translateY('+translateY+'px) rotateZ('+rotate+'deg)';

            window.requestAnimationFrame(updateAnimation);
        })();

        document.documentElement.addEventListener('turbo:before-render', fragment.turboBeforeRender = () => {
            img.style.display = 'none';
        });
    }

    fragmentTargetDisconnected(fragment) {
        console.debug(fragment);
        document.documentElement.removeEventListener('turbo:before-render', fragment.turboBeforeRender);
    }
}
