export const foreachElement = (selector, callback) => {
    document.querySelectorAll(selector).forEach(callback);

    new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(function (element) {
                    if (element.matches && element.matches(selector)) {
                        callback(element);
                    }

                    if (element.querySelectorAll) {
                        element.querySelectorAll(selector).forEach(callback);
                    }
                })
            }
        }
    }).observe(document, {
        attributes: false,
        childList: true,
        subtree: true
    });
};
